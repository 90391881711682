<template>
  <div id="ChequeNovoModal" >
    <v-card class="container pa-0" >
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="justify-space-between pt-3 pb-2 px-3">
        <span class="white--text title-page body-1">Novo Cheque de Terceiro</span>
          <v-btn @click="fechar" icon dark color="#F2F6F7">
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
      </v-card-title>
       
      <v-card-text class="wrapper pa-0">
        <v-divider dark></v-divider>

        <!-- Dados ---------------------- -->
        <div>
          <v-row class="px-5">
            <v-col>
              <v-form ref="form" v-model="valid">
                <fieldset class="rounded" style="border: none">
                  <v-row class="mt-2">
                    <v-col col="8" class="px-2">
                      <v-text-field
                        v-model="chequeForm.fin_cheque_titular"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Titular"
                        maxlength="100"
                        label="Titular" />
                    </v-col>
                    <v-col col="4" class="px-2">
                      <v-text-field
                        v-model="chequeForm.fin_cheque_cpf"
                        filled
                        dense
                        outlined
                        :rules="cpfRules"
                        v-mask="['###.###.###-##', '##.###.###/####-##']"
                        background-color="#FFF"
                        placeholder="CPF / CNPJ"
                        label="CPF / CNPJ"
                        maxlength="20" />
                    </v-col>
                  </v-row>  
                    
                  <v-row class="mt-n5">
                    <v-col cols="12" class="pt-0 px-2">
                      <v-select
                        v-model="chequeForm.cod_banco"
                        class="ma-0"
                        hide-details
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        :items="opcoesBanco"
                        label="Banco *"
                        :rules="notEmptySelectRule"
                        item-text="text"
                        item-value="id"
                        item-key="id" />
                    </v-col>
                  </v-row>  
                    
                  <v-row class="mt-5">
                    <v-col cols="6" class="pt-0 px-2">
                      <v-text-field
                        v-model="chequeForm.fin_cheque_agencia"
                        filled
                        dense
                        outlined
                        :rules="agenciaRules"
                        background-color="#FFF"
                        placeholder="Agência"
                        label="Agência *" />
                    </v-col>
                  
                    <v-col cols="6" class="pt-0 px-2">
                      <v-text-field
                        v-model="chequeForm.fin_cheque_conta"
                        filled
                        dense
                        :rules="agenciaRules"
                        outlined
                        background-color="#FFF"
                        placeholder="Conta"
                        label="Conta *"
                        maxlength="15" />
                    </v-col>
                  
                  </v-row>  

                  <v-row class="mt-n5">
                    <v-col cols="6" class="pt-0 px-2">
                      <v-text-field
                        v-model="chequeForm.fin_cheque_numero"
                        filled
                        dense
                        :rules="agenciaRules"
                        outlined
                        background-color="#FFF"
                        type="number"
                        placeholder="Num. Cheque"
                        label="Num. Cheque *" />
                    </v-col>
                    <v-col cols="6" class="pt-0 px-2">
                      <!-- <v-text-field v-model="chequeForm.fin_cheque_valor" filled dense outlined background-color="#FFF" placeholder="Valor" label="Valor" maxlength="100"/> -->
                      <VuetifyMoney
                        type="tel"
                        v-model="chequeForm.fin_cheque_valor"
                        filled
                        :rules="notEmptyRule"
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Valor"
                        label="Valor *"
                        maxlength="17"
                        reverse />
                    </v-col>
                  </v-row>  

                  <v-row class="mt-n5">
                    <v-col cols="6" class=" pt-0 px-2">
                      <DatePicker v-model="chequeForm.fin_cheque_dt_bom_para" label="Bom para" class="mt-1" outlined backgroundColor="#FFF" :style="data_picker" dense />
                    </v-col>
                    <v-col cols="6" class="pt-0 px-2">
                      <DatePicker v-model="chequeForm.fin_cheque_devolvido" label="Devolução" class="mt-1" outlined backgroundColor="#FFF" :style="data_picker" dense />
                    </v-col>
                  </v-row>

                  <v-row class="mt-n5">
                    <v-col cols="6" class="pt-0 px-2">
                      <v-text-field v-model="chequeForm.fin_cheque_devolvido_obs" filled dense outlined background-color="#FFF" placeholder="Motivo" label="Motivo" maxlength="100"/>
                    </v-col>
                    <v-col cols="6" class="pt-0 px-2">
                      <v-select v-model="chequeForm.fin_cheque_compensado" class="ma-0" hide-details filled dense outlined background-color="#FFF" :items="opcoesChequeCompensado" label="Compensado" item-text="text" item-key="id" />
                    </v-col>
                  </v-row>
                </fieldset>  
              </v-form>
            </v-col>
          </v-row>
        </div>  


      </v-card-text>
      
      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn @click="fechar" class="mr-4 btn caption font-weight-medium" color="primary" text>
          Cancelar
        </v-btn>
        <v-btn @click="isEdit ? editarCheque() : criarCheque()" :disabled="!valid" class="btn white--text caption font-weight-medium" color="primary accent-4" :loading="isSaveChequeLoading">
          {{ isEdit ? 'Editar' : 'Salvar' }}
        </v-btn>
      </v-footer>
    </v-card>
  </div>
</template>

<script>
import store_Pessoa from "../../Pessoas/store_Pessoa";
// import store_ContasPagar from "../ContasPagar/store_ContasPagar"
import moment from 'moment'
import { ContasPagarGet } from "../../../services/erp"
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
import VuetifyMoney from "../../ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue";
import store_Financeiro from "../store_Financeiro";
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SUBTITULO, MASK_CPFCNPJ, MASK_TELEFONE } from "../../../services/constantes";
import {
  CpfCnpjIsValid,
  telefoneIsValid,
  formatDate,
  maskCpfCnpj,
  formatPrice
} from "../../../services/funcoes";
import store_site from "../../../../src/store/store_site"
import ContasReceber from "./ContasReceber.vue";
import { API } from "../../../services/API";

// import PessoaConsultaMobile from '../../Pessoas/PessoaConsultaMobile.vue'

export default {
  name: "ChequeNovoModal",

  props: ['dialog_novo_editar', 'dados_editar', 'isChequeModalOpen', 'pagador', 'selectedCheque', 'isChequeTerceiro', 'editar'],

  components: {
    DatePicker,
    VuetifyMoney
  },

  data() {
    return {
      store_Financeiro    : store_Financeiro,
      store_site          : store_site,
      // store_ContasPagar : store_ContasPagar,
      store_Pessoa      : store_Pessoa,
      ContasReceber     : ContasReceber,
 
      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SECUNDARIA    : COR_SECUNDARIA,
      COR_SUBTITULO     : COR_SUBTITULO,  
      MASK_CPFCNPJ      : MASK_CPFCNPJ,
      MASK_TELEFONE     : MASK_TELEFONE,
      formatDate        : formatDate,
      maskCpfCnpj       : maskCpfCnpj,
      formatPrice       : formatPrice,

      dialogPagador: false,
      opcoesBanco  : [],

      // chequeForm: {
      //   cod_pessoaconta: null,
      //   cod_banco: 1, 
      //   banco_nome: null,
      //   fin_cheque_agencia: null,
      //   fin_cheque_agencia_dv: null,
      //   fin_cheque_conta: null,
      //   fin_cheque_conta_dv: null,
      //   fin_cheque_numero: null,
      //   fin_cheque_titular: null,
      //   fin_cheque_cpf: null,
      //   fin_cheque_dt_cadastro: moment().format(),
      //   fin_cheque_valor: null,
      //   fin_cheque_dt_bom_para: null,
      //   fin_cheque_pago: 'N',
      //   fin_cheque_compensado: 'N',
      //   fin_cheque_devolvido: null,
      //   fin_cheque_devolvido_obs: null
      // },
      chequeForm: {
        cod_pessoaconta: null,
        cod_banco: 3, 
        banco_nome: null,
        fin_cheque_agencia: 'agencia',
        fin_cheque_agencia_dv: null,
        fin_cheque_conta: '11',
        fin_cheque_conta_dv: null,
        fin_cheque_numero: 33,
        fin_cheque_titular: 'titular',
        fin_cheque_cpf: 22127150805,
        fin_cheque_dt_cadastro: moment().format(),
        fin_cheque_valor: 220,
        fin_cheque_dt_bom_para: null,
        fin_cheque_pago: 'N',
        fin_cheque_compensado: 'N',
        fin_cheque_devolvido: null,
        fin_cheque_devolvido_obs: null
      },
      isSaveChequeLoading: false,

      valid: false,
      form: false,

      data_picker : {
        'min-width':'150px !important',
      },

      notEmptyRule: [value => value?.length > 0  || 'Esse campo é obrigatório'],
      notEmptySelectRule: [ value => !!value || 'Esse campo é obrigatório'],
      numChequeRules: [
        value => !!value || 'Esse campo é obrigatório',
        value => value?.length <= 15 || 'O número não pode ter mais que 15 números.',
      ],
      digitoRules: [ value => value?.length <= 1 || 'Somente 1 dígito' ],
      digitoObrigatorioRules: [ 
        value => !!value || 'Campo obrigatório',
        value => value?.length <= 1 || 'Somente 1 dígito' 
      ],
      agenciaRules : [
        value => !!value || 'Esse campo é obrigatório' + value,
        value => value?.length <= 15 && value?.length > 0 || 'O número não pode ter mais que 15 números.',
      ],

      cpfRules: [
        (value) => (!value || CpfCnpjIsValid(value)) || "O CPF/cnpj não é válido",
        
      ],

    }
  },

  computed: {    
    isEdit () {
      return this.editar
    },

    opcoesChequeCompensado () {
      return [
        { id: 1, text: 'SIM', value: 'S'},
        { id: 2, text: 'NÃO', value: 'N'}
      ]
    }
  },

  created () {
    console.log("🚀 ~ file: ChequeNovoModal.vue:300 ~ created ~ this.selectedCheque:", this.selectedCheque)
    if (Object.keys(this.selectedCheque).length) {
      this.chequeForm = { ...this.selectedCheque }
    } 
    
  },

  mounted(){
    this.getBancos()
  },
  methods: {
    async getBancos() {
      const resposta = await API.get("banco");
      if (resposta) {
        if (resposta.data.result != 'Não possui dados') {
          this.opcoesBanco =  resposta.data.result.map(banco => {
        return { 
          id: banco.cod_banco, 
          text: `${banco.banco_cod_compensacao} - ${banco.banco_nome}`, 
        }
      })
        }
      }
      // return [
      //   { id: 1, value: 1, text: 'BANCO 1'}
      // ]
    },

    toggleSelectClientDialog () {
      this.dialogPagador = !this.dialogPagador
    },

    async criarCheque () {
      let response

      try {
        this.isSaveChequeLoading = true
        response = await API.post('/erp/cheques', { ...this.chequeForm })
        console.log("🚀 ~ file: ChequeNovoModal.vue:370 ~ criarCheque ~ response:", response)
        this.chequeForm = response.data?.result?.data

        this.$emit('chequeCriado', { ...this.chequeForm })
      } catch(e) {
        console.log('ERR ->', e.message)
      } finally {
        this.isSaveChequeLoading = false
        this.fechar()

        if (response.status == 200) {
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = response.data.result.result.trim();
          this.store_site.alert           = true;
        } else {
          // Mensagem de exclusão não realizada
          this.store_site.alert_cor       = "#FF0000";
          this.store_site.alert_timeout   = 10000;
          this.store_site.alert_msg       = response.data.result.trim();
          this.store_site.alert           = true;
        }

      }
    },

    async editarCheque () {
      let response
      
      try {
        this.isSaveChequeLoading = true

        response = await API.put(`/erp/cheques/${this.selectedCheque.cod_fin_cheque}`, { ...this.chequeForm })
        this.chequeForm = response.data?.result?.data

        this.$emit('chequeEditado', { ...this.chequeForm })
      } catch (e) {
        console.log('ERR ->'. e.message)
      } finally {
        this.isSaveChequeLoading = false
        this.fechar()

        if (response.status == 200) {
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = response.data.result.result.trim();
          this.store_site.alert           = true;
        } else {
          // Mensagem de exclusão não realizada
          this.store_site.alert_cor       = "#FF0000";
          this.store_site.alert_timeout   = 10000;
          this.store_site.alert_msg       = response.data.result.trim();
          this.store_site.alert           = true;
        }

      }
    },

    fechar() {
      this.$emit('update:isChequeModalOpen', false);
    }
  },
};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
  font-size: 19px!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.title-page-menor {
  font-family: "Open Sans", sans-serif!important;
  font-size: 16px!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.wrapper {
  height: 500px;
  overflow-y: auto;
}

.row-value {
  margin-top: -146px;
}

.btn {
  width: 100px;
}

.v-divider {
  background: var(--COR_PRINCIPAL)!important;
}
</style>

<style scoped lang="scss">

    .input-text {
        ::v-deep {
            .v-text-field {
                input {
                    font-size: 19px;
                }
            }
        }
    }
</style>